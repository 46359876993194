import React, { useEffect, useState } from 'react'
import './ContactUs.scss'
import Navigation from '../../Components/Navigation/Navigation'
import Footer from '../../Components/Footer/Footer'
import Heading from '../../Components/Heading/Heading'
import Contect from '../Home/Components/Contect'
import Map from './Components/Map'
import { useParams } from 'react-router-dom'

export default function ContactUs() {

  return (
    <>
    <Navigation offer={true}/>
    <Heading Title="Contact Us" MiniTitle="" Links="Contact Us"/>
    <Map/>
    <Contect/>
    <Footer/>
    </>
  )
}
