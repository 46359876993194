import React,{useEffect} from 'react'
import './Services.scss'
import Footer from '../../Components/Footer/Footer'
import Contect from '../Home/Components/Contect'
import Navigation from '../../Components/Navigation/Navigation'
import Heading from '../../Components/Heading/Heading'
import Description from './Components/Description'
import Technologies from './Components/Technologies'
import ReactGA from 'react-ga';


export default function Services() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <>
        <Navigation offer={true}/>
        <Heading Title="Services" MiniTitle="" Links="Services"/>
        <Description/>
        <Technologies/>
        <Contect/>
        <Footer/>
    </>
  )
}
