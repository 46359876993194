import React from "react";
import Tick1 from "./../../../assets/images/tick1.svg";
import Tick2 from "./../../../assets/images/tick2.svg";
import Tick3 from "./../../../assets/images/tick3.svg";
import Tick4 from "./../../../assets/images/tick4.svg";
import JSONFILe from "./../../../Data/Case_Studies.json";

export default function UseCase({ id, selectedApp }) {

    const handleRightColor = (i) => {
        if(i === 0) {
            return Tick1;
        }
        else if(i === 1) {
            return Tick2;
        }
         else if(i === 2) {
            return Tick3;
        }
         else {
            return Tick4
        }
    };

  return (
    <section className="use_case">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <div className="content" data-aos="fade-right">
              <div className="title">Use Cases</div>
              <div className="desc">
                Bulit for everybody with unlimited unique features
              </div>
              <div className="usecase_box">
                {selectedApp?.use_cases &&
                  selectedApp?.use_cases?.useCases?.length > 0 &&
                  selectedApp?.use_cases?.useCases?.map((u, i) => (
                    <div className="usecase_row" key={i}>
                      <div className="icon">
                        <img src={handleRightColor(i)} alt="" />
                      </div>
                      <div className="text">
                        {u}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="usecase_image" data-aos="fade-left">
              <img src={`${process.env.PUBLIC_URL}${selectedApp.use_cases_img}`} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
