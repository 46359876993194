  import React,{useState, useEffect} from 'react'
  import CountUp from 'react-countup';
  import Aos from 'aos';
  import 'aos/dist/aos.css';
  
  export default function WhyChooseUs() {

    const [isVisible, setIsVisible] = useState(false);
    const [count, setCount] = useState(0);

  useEffect(() => {
    const options = {
      root: null, 
      rootMargin: '0px', 
      threshold: 0.5, 
    };

    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    const targetDiv = document.getElementById('target-div'); 
    if (targetDiv) {
      observer.observe(targetDiv);
    }

    return () => {
      observer.disconnect(); 
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
     
      setCount(100); 
    }
  }, [isVisible]);
  
    
    return (
      <section className='why_choose'>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-5">
              <div className="wc_title" data-aos="fade-right">
                <h2>Why Choose us</h2>
                <p><span className='text_icon'>10+</span> years of Delivering Cutting-Edge Digital Solutions</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-xl-7"  >
              <div className="row">
            <div id="target-div" className={isVisible ? 'visible' : 'hidden'}></div>
                <div className="col-md-3 col-6">
                  <div className="wc_box">
                    <div className="count">{isVisible && (
                    <CountUp start={0} end={80} duration={2} decimals={0} separator="," />)}<span className='text_icon'>+</span> </div>
                    <div className="count_text">Apps Developed</div>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="wc_box">
                    <div className="count">{isVisible && (
                    <CountUp start={0} end={25} duration={2} decimals={0} separator="," />)}<span className='text_icon'>+</span> </div>
                    <div className="count_text">Cloud Softwares</div>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="wc_box">
                    <div className="count">{isVisible && (
                    <CountUp start={0} end={10} duration={2} decimals={0} separator="," />)}<span className='text_icon'>+</span> </div>
                    <div className="count_text">Countries Served</div>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="wc_box">
                    <div className="count">{isVisible && (
                    <CountUp start={0} end={25} duration={2} decimals={0} separator="," />)}<span className='text_icon'>+</span> </div>
                    <div className="count_text">Team Members</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
