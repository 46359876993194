import React from 'react'
import './OurClients.scss'
import Navigation from '../../Components/Navigation/Navigation'
import Footer from '../../Components/Footer/Footer'
import Heading from '../../Components/Heading/Heading'
import Contect from '../Home/Components/Contect'
// import Clients from '../AboutUs/Components/OurClients'
import Clients from '../Home/Components/Clients'
import Content from './Components/Content'
import LetSpeak from './Components/LetSpeak'

export default function OurClients() {
  return (
    <>
    <Navigation offer={true}/>
    <Heading Title="Our Clients" MiniTitle="" Links="Our Clients"/>
    <Content/>
    <LetSpeak/>
    <Clients/>
    <Contect/>
    <Footer/>
    </>
  )
}
