import React,{useEffect} from "react";
import Cultureimg from './../../../Data/OurCulture.json'
import AOS from "aos";
import 'aos/dist/aos.css'

export default function OurCulture() {
  useEffect(()=>{
    AOS.init({
      duration: 3000,
    });
  },[])
  return (
    <section className="ourculture">
      <div className="container">
        <div className="row">
          <div className="culture_heading_wrapper">
            <div className="culture_title" data-aos="fade-right">
              <span>Our Culture</span>
            </div>
          </div>
        </div>        
      </div>
      <div className="culture_img_wrapper">
            <div className="container">
              <div className="row">
               {Cultureimg.map((a, b) => (
                <div className="col-md-12 col-sm-6 col-lg-4" data-aos="fade-up" data-aos-delay={b * 500}>
                    <div className="culture_img" data-aos="fade-up" data-aos-delay={b * 500}><img src={`${process.env.PUBLIC_URL}${a.img}`} alt="" /></div>
                </div>
               ))} 
              </div>
            </div>
          </div>
    </section>
  );
}
