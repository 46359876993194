import React,{useState, useEffect} from 'react'
import './WorkDetails.scss'
import Navigation from '../../Components/Navigation/Navigation'
import Footer from '../../Components/Footer/Footer'
import Heading from '../../Components/Heading/Heading'
import WorkData from './../../Data/OurWork.json'
import { useParams } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function WorkDetails() {
    const [filteredData, setFilteredData] = useState([]);
    let { link_title } = useParams();
 
    useEffect(() => {
        if(link_title) {
            filterDataById(link_title)
        }
    }, [link_title]);

    const filterDataById = (link_title) => {
        const filteredItems = WorkData.filter(item => item.link_title == link_title);
        setFilteredData(filteredItems[0]);
    };

    useEffect(()=>{
        AOS.init({
            duration: 3000,
        });
    },[])
  return (
    <>
      <Navigation offer={true}/>
        <Heading Title={filteredData?.title} MiniTitle="" Links=""/>
        <section className='work_details'>
            {/* {selectedApp.map((data, id))} */}
            <div className="container" key={filteredData.link_title}>
                <div className="about_project" data-aos="fade-right">
                    <div className="title"> About {filteredData?.title}</div>
                    <div className="desc">{filteredData?.description}</div>
                </div>
                <div className="tech_used" data-aos="fade-up">
                    <div className="title">TECHNOLOGY USED</div>
                    <div className="tech_wrapper">
                        {
                            filteredData?.technology?.map((item) => (
                                <div className="tech_title">{item}</div>
                            ))
                        }
                    </div>
                    <div className="download_app">
                        <a href={`${filteredData.plink}`} target='_blank'><img src="/images/OurWork/icons/playstore.png" alt="" /></a>
                        <a href={`${filteredData.alink}`} target='_blank'><img src="/images/OurWork/icons/appstore.png" alt="" /></a>
                    </div>
                </div>
                <div className="work_img" data-aos="fade-down-left">
                    <img src={`${process.env.PUBLIC_URL}${filteredData.image}`} alt="" />
                </div>
                
            </div>
        </section>
        <Footer/>
    </>
  )
}
