import { useEffect, useState } from 'react'
import './App.scss'
import { Routes, Route, useLocation } from 'react-router-dom'
import Home from './Pages/Home/Home'
import CaseStudies from './Pages/CaseStudies/CaseStudies'
import OurWorks from './Pages/OurWorks/OurWorks'
import AboutUs from './Pages/AboutUs/AboutUs'
import Industries from './Pages/Industries/Industries'
import Works from './Pages/Works/Works'
import ContactUs from './Pages/ContactUs/ContactUs'
import OurClients from './Pages/OurClients/OurClients'
import PageNotFound from './Pages/404/404'
import OurStory from './Pages/AboutUs/Components/OurStory'
import OurJourney from './Pages/AboutUs/Components/OurJourney'
import LifeatKoolMind from './Pages/AboutUs/Components/LifeatKoolMind'
import Services from './Pages/Services/Services'
import WorkDetails from './Pages/WorkDetails/WorkDetails'
import ReactGA from 'react-ga';
import Career from './Pages/Career/Career'

ReactGA.initialize('G-WT6EEX895N');

function App() {
  const { pathname } = useLocation();
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route  path="/" element={<Home />} />
        <Route  path="case-studies" element={<CaseStudies />} />
        <Route  path="case-studies/:route_title" element={<OurWorks />} />
        <Route  path="our-works" element={<Works />} />
        <Route  path="work-details/:link_title" element={<WorkDetails />} />
        <Route  path="services" element={<Services />} />
        <Route  path="about" element={<AboutUs />} />
        <Route  path="our-story" element={<OurStory />} />
        <Route  path="our-journey" element={<OurJourney />} />
        <Route  path="life-at-koolmind" element={<LifeatKoolMind />} />
        <Route  path="contact" element={<ContactUs />} />
        <Route  path="industries" element={<Industries />} />
        <Route  path="our-clients" element={<OurClients />} />
        <Route path="career" element={<Career/>} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  )
}

export default App
