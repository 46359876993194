import React from 'react'
import './404.scss'
import Navigation from '../../Components/Navigation/Navigation'
import Footer from '../../Components/Footer/Footer'
import { Link } from 'react-router-dom'

export default function PageNotFound() {
  return (
    <>
        <Navigation offer={true}/>
        <section className='not_fount'>
            <h1>404</h1>
            <h3>Page not found</h3>
            <Link to=''>Back to home page</Link>
        </section>
        <Footer/>
    </>
  )
}
