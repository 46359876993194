import React,{useEffect} from "react";
import CaseCard from "./CaseCard";
import { Link, useParams } from "react-router-dom";
import data from "../../../Data/Case_Studies.json";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function CaseContainer() {
  let { id } = useParams();
  useEffect(()=>{
    AOS.init({
      duration: 1500,
    });
  },[])

  return (
    <>
      <div className="cc_wrapper">
        <div className="container">
          <div className="case_page_title" data-aos="fade-up">Let's have a look at some of our case studies</div>
          <div className="row">
            {data &&
              data.length > 0 &&
              data.map((d, i) => (
                <div className="col-md-6 col-lg-6">
                  {/* <Link to={`/case-studies/${d.id}`}>
                  </Link> */}
                    <CaseCard details={d} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
