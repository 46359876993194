import React, { useEffect, useState } from "react";
import Go from "./../../../assets/images/go.svg";
import GoBlue from "./../../../assets/images/blue_go.svg";
import uiux from "./../../../assets/images/ux-design 1.svg";
import blockchain from "./../../../assets/images/blockchain 1.svg";
import appdevelopment from "./../../../assets/images/App-development.png";
import software from "./../../../assets/images/code 1.png";
import mobileIcon from "./../../../assets/images/mobileIcon.svg";
import cloudIcon from "./../../../assets/images/cloudIcon.svg";
import aiIcon from "./../../../assets/images/aiIcon.svg";
import uiIcon from "./../../../assets/images/uiIcon.svg";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import TagManager from "react-gtm-module";

const data = [
  {
    icon: mobileIcon,
    title: "Intelligent Mobile Apps Development",
    desc: "Deploy cutting-edge mobile applications with our expert IT strategies that guarantee market relevance and user satisfaction.",
  },
  {
    icon: cloudIcon,
    title: "Custom Cloud Software Development",
    desc: "Our specialized IT architectures in cloud services deliver enhanced security, adaptability, and seamless enterprise integration.",
  },
  {
    icon: aiIcon,
    title: "AI/ML Integrations",
    desc: "We integrate powerful AI algorithms and machine learning for smarter IT solutions that redefine business intelligence and efficiency.",
  },
  {
    icon: uiIcon,
    title: "UI/UX Design",
    desc: "Our IT expertise crafts user-centric designs that elevate interaction, ensuring seamless functionality and aesthetic appeal.",
  },
];

export default function WhatCanWeDo() {
  // const [scroll, setScroll] = useState(false);
  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     setScroll(window.scrollY > 200);
  //   });
  // }, []);

  // const [scrolly, setScrolly] = useState(false);
  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     setScrolly(window.scrollY > 450);
  //   });
  // }, []);
  const handleClickServiceBox = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "click_Home_Service_Box",
      },
    });
  };

  useEffect(() => {
    AOS.init({
      duration: 4000,
    });
  }, []);

  return (
    <section className="what_cw_do">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="heading">
              <h2>What Can We Do</h2>
              <p>Our Expertise</p>
              <div className="big">
                <span className="title_span" data-aos="fade-right">
                  {" "}
                  Innovate.{" "}
                </span>
                <span className="title_span" data-aos="zoom-out">
                  {" "}
                  Build.{" "}
                </span>
                <span className="title_span" data-aos="fade-left">
                  {" "}
                  SOLVE.
                </span>
              </div>
            </div>
          </div>
          {/* <div className="col-md-12 col-lg-6"data-aos="zoom-in-up" data-aos-duration="3000">
            <div className="service_box box1">
              <div className="title">
                <div className="text">End-To-End Services</div>
                <div className="icon">
                  <Link to="/services" onClick={handleClickServiceBox}>
                    <img src={Go} alt="" />
                  </Link>
                </div>
              </div>
              <div className="services">
                <div className="service_wrapper">
                  <div className="icon">
                    <img src={appdevelopment} alt="" />
                  </div>
                  <div className="text">
                    Intelligent Mobile Apps Development
                  </div>
                </div>
                <div className="service_wrapper">
                  <div className="icon">
                    <img src={software} alt="" />
                  </div>
                  <div className="text">Custom Cloud Software Development</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 d-flex" data-aos="zoom-in-up" data-aos-duration="3000">
            <div className="service_box box2">
              <div className="title">
                <div className="text">Futuristic Services</div>
                <div className="icon">
                  <Link to="/services" onClick={handleClickServiceBox}>
                    <img src={Go} alt="" />
                  </Link>
                </div>
              </div>
              <div className="services">
                <div className="service_wrapper">
                  <div className="icon">
                    <img src={blockchain} alt="" />
                  </div>
                  <div className="text">Blockchain Development</div>
                </div>
                <div className="service_wrapper">
                  <div className="icon">
                    <img src={uiux} alt="" />
                  </div>
                  <div className="text">UI/UX Design</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="view_all">
              <button>
                View All Services <img src={GoBlue} alt="" />
              </button>
            </div>
          </div> */}
        </div>
        <div className="row my-5">
          {data.map((d) => (
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="service_card">
                <img src={d.icon} />
                <span>{d.title}</span>
                <p>{d.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
