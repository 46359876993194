import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import HeroBack from "./../../../assets/images/dots_back.png";
import HeroImg1 from "./../../../assets/images/Image1.png";
import HeroImg2 from "./../../../assets/images/Image2.png";
import HeroImg3 from "./../../../assets/images/Image3.png";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";
import Typewriter from 'typewriter-effect'
import TagManager from "react-gtm-module";

export default function Hero() {
  const options = {
    items: 1,
    nav: false,
    rewind: true,
    dots: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  return (
    <section className="hero">
      <img className="back" src={HeroBack} alt="" />
      <div className="container">
        <div className="hero_wrapper">
          <div className="row">
            <div className="col-md-12 col-lg-5">
              <div className="content">
                <div className="title">
                  <h2>
                    <span>Your</span> Responsible
                  </h2>
                </div>
                <div className="sub_title">
                  <h3>
                    <span>Technology</span>&nbsp;Partner
                  </h3>
                </div>
                <div className="desc">
                  <Typewriter
	                options={{ delay: 0, deleteSpeed: false, loop: false }}
                	onInit={(typewriter) => {
		              typewriter
                  .typeString('Making Technology Work for Your Business:<br> Our<p style="color: #1886CD; display: inline;"> Responsibility </p> and <p style="color: #1886CD; display: inline;"> Commitment</p>')
                  .start();
                    }} 
                  />
                       {/* <Typewriter
	                options={{ delay: 0, deleteSpeed: false, loop: false }}
                	onInit={(typewriter) => {
		              typewriter
			            .typeString("Making Technology Work for Your Business:<br>Our Responsibility and Commitment")
                  .typeString('<span style="color: red;">Commitment</span>')
                  .start();
                    }}
                  /> */}
                  {/* <span>
                    Making Technology Work for Your Business:{" "}
                    <span>Our Responsibility and Commitment</span>
                  </span> */}
                </div>
                <div className="hero_action">
                  <NavLink to="/case-studies">Case Studies</NavLink>
                  <NavLink to="/our-clients">Our Clients</NavLink>
                </div>
                <div className="clutch_icon">
                <iframe className="clutch" id="iframe-0.10599330599977264" width="100%" src="https://widget.clutch.co/widgets/get/2?ref_domain=localhost&amp;uid=2136696&amp;rel_nofollow=true&amp;ref_path=/" title="[iFrameSizer]iframe-0.10599330599977264:0:0:mouseleave:903:3892"></iframe>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-7">
              <div className="hero_graphic">
                <div className="graphic_img_wrapper">
                  <OwlCarousel options={options}>
                    <img src={HeroImg1} alt="" />
                    <img src={HeroImg2} alt="" />
                    <img src={HeroImg3} alt="" />
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
