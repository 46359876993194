import React,{useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import './../career.scss'
export default function Description() {
  useEffect(()=>{
    AOS.init({
      duration: 1500,
    });
  },[])
  return (
    <div className='desc_section'>
        <div className="container" data-aos="fade-up">
        If you're ready to join us, please share your CV with us via email at <span className='email'><a href="mailto:sales@koolmind.in">hr@koolmind.in.</a></span> We're excited to learn more about you and how your skills can contribute to our team. 
        </div>
    </div>
  )
}
