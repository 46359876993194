import React from "react";

const WhatWeBelive = () => {
  return (
    <section className="what_we_believe">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="heading">
              <h2>What We Believe</h2>
              <p>
                We believe in the transformative potential of{" "}
                <span>Mobile & Web Apps</span>, providing simplified and
                portable solutions that enrich lives. Our commitment is to
                support our clients' success by offering end-to-end{" "}
                <span>Mobile & Web Development Services</span> that embody these
                principles.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeBelive;
