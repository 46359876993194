import React from 'react'
import './Heading.scss'
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

export default function Heading(props) {
  const {Title, MiniTitle, Links} = props

  useEffect(()=>{
    AOS.init({
      duration: 1000,
    });
  },[])
  return (
    <section className='heading_container'>
        <div className="container">
            <div className="heading_wrapper"  data-aos="zoom-in">
              {
                MiniTitle &&
                <div className="page_name">{MiniTitle}</div>
              }
              {
                Title &&
                <div className="page_title">{Title}</div>
              }
              {
                Links &&
                <div className="page_links"><Link to='/'>Home</Link> &gt; <span>{Links}</span></div>
              }
            </div>
        </div>
    </section>
  )
}