import React,{useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Description() {
  useEffect(()=>{
    AOS.init({
      duration: 1500,
    });
  },[])
  return (
    <div className='desc_section'>
        <div className="container">
            <div className="disc_text" data-aos="fade-up">
                We create awesome and stunning digital solutions. 
            </div>
        </div>
    </div>
  )
}
