import React from 'react'


export default function Solution({id, selectedApp}) {
  return (
    <section className='requirement'>
    <div className="container">
        <div className="requirement_wrapper" data-aos="fade-up">
            <div className="title">Solution We Provided</div>
            {selectedApp?.project_challenges?.solutions &&
              selectedApp.project_challenges.solutions.length >= 0 &&
              selectedApp.project_challenges.solutions.map((j, k) => (
                <div className="desc" key={k}>{j}</div>
              ))
            }
        </div>
    </div>
</section>
  )
}
