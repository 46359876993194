import React from 'react'
import Navigation from '../../../Components/Navigation/Navigation'
import Contect from '../../Home/Components/Contect'
import Footer from '../../../Components/Footer/Footer'
import Image from './../../../assets/images/koolteam.jpeg'
import AboutHeading from './AboutHeading'

export default function OurJourney() {
  return (
    <>
        <Navigation offer={true}/>
        <AboutHeading Title='KoolMind Journey' Image={Image}/>
        <section className="journy">
          <div className="container">
            <div className="journy_title" data-aos="fade-up">At Koolmind, we believe that every team member brings unique skills, talents, and perspectives to the table. We value collaboration, respect, and open communication in everything we do.</div>
            <div className="journy_progress">
              <div className="progress_row">
                <div className="prog_img">
                  <img src="images/journey/2013-14.gif" alt="" />
                </div>
                <div className="content">
                  <div className="title">2013-14</div>
                  <div className="desc">Our founder Neel Mevada, Began developing apps both professionally and independently</div>
                </div>
              </div>
              <div className="progress_row swap">
                <div className="prog_img">
                  <img src="images/journey/2015.gif" alt="" />
                </div>
                <div className="content">
                  <div className="title">2015</div>
                  <div className="desc">Neel relocates to Bangalore</div>
                </div>
              </div>
              <div className="progress_row">
                <div className="prog_img">
                  <img src="images/journey/2017.gif" alt="" />
                </div>
                <div className="content">
                  <div className="title">2017</div>
                  <div className="desc">Neel then founded KoolMind Technologies, a full-time app development studio.</div>
                </div>
              </div>
              <div className="progress_row swap">
                <div className="prog_img">
                  <img src="images/journey/2018.gif" alt="" />
                </div>
                <div className="content">
                  <div className="title">2018</div>
                  <div className="desc">Experienced a setback with KoolMind Technologies and returned to employment.</div>
                </div>
              </div>
              <div className="progress_row">
                <div className="prog_img">
                  <img src="images/journey/2020.gif" alt="" />
                </div>
                <div className="content">
                  <div className="title">2020</div>
                  <div className="desc">Restarted KoolMind Technolabs with one employee</div>
                </div>
              </div>
              <div className="progress_row swap">
                <div className="prog_img">
                  <img src="images/journey/2022.gif" alt="" />
                </div>
                <div className="content">
                  <div className="title">2022</div>
                  <div className="desc">Received positive feedback from clients with 10+ projects delivered and a growing team of 25+ talented individuals.</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Contect/>
        <Footer/>
    </>
  )
}
