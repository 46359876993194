import React from 'react'
import Navigation from '../../Components/Navigation/Navigation'
import Footer from '../../Components/Footer/Footer'
import Heading from '../../Components/Heading/Heading'
import CaseContainer from './Components/CaseContainer'
import Contact from './../Home/Components/Contect'
import './CaseStudies.scss'

export default function CaseStudies() {
  
  return (
    <>
    <Navigation offer={true}/>
      <Heading Title="Case Studies" MiniTitle="" Links="Case Studies"/>
      <CaseContainer/>
      <Contact/>
    <Footer/>
    </>
  )
}
