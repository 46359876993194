import React from 'react'
import jsondata from "./../../../Data/Case_Studies.json";
export default function VisualInterface({id, selectedApp}) {
  return (
    <section className='visual_interface'>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="title" data-aos="fade-up">Amazing visual interface</div>
                    <div className="desc" data-aos="fade-up">Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor tempor incididunt ut labore dolore magna.</div>
                    <div className="visual_image"  data-aos="fade-left" >
                        <img src={`${process.env.PUBLIC_URL}${selectedApp.visual_image}`} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
