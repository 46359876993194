import React,{useEffect} from "react";
import TeamJson from "./../../../Data/Team.json";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Team() {

  useEffect(()=>{
    AOS.init({
      duration: 3000,
    });
  },[])

  return (
    <section className="team">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title" data-aos="fade-up">Meet The Core Team</div>
            <div className="desc" data-aos="fade-up">Top Peoples Behind Work</div>
            <div className="team_wrapper">
              {TeamJson.map((details, id) => (
                <div className="team_box" key={id} >
                  <div className="team_img">
                    <img 
                      src={`${process.env.PUBLIC_URL}${details.picture}`}
                      alt=""
                    />
                  </div>
                  <div className="team_detail">
                    <div className="name">{details.name}</div>
                    <div className="position">{details.position}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
