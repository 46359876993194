import React from "react";
import Tick from "./../../../assets/images/tick.svg";
import JSONFIle from "./../../../Data/Case_Studies.json";

export default function Challenges({ id, selectedApp }) {
  return (
    <section className="challenges">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title" data-aos="fade-right">Project Challenges</div>
          </div>

          {selectedApp?.project_challenges?.challenges &&
            selectedApp.project_challenges.challenges.length > 0 &&
            selectedApp.project_challenges.challenges.map((c, i) => (
              <div className="col-md-12 col-lg-12 col-xl-6" key={i} data-aos="fade-left" data-aos-delay={i * 500}>
                <div className="challenge_wrapper">
                  <div className="icon">
                    <img src={Tick} alt="" />
                  </div>
                  <div className="text">
                    {c}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}
