import React,{useEffect} from 'react'
import Ply from './../../../assets/images/ply.svg'
import clientsjson from './../../../Data/ClientsSpeak.json'
import AOS from 'aos';
import 'aos/dist/aos.css';
import TagManager from 'react-gtm-module';

export default function LetSpeak() {

    useEffect(()=>{
        AOS.init({
            duration: 1000,
        });
    },[])

    const handleClickImage =(id)=>{
        if(id == 0){
            TagManager.dataLayer({
                dataLayer: {
                    event: 'Our-Clients Yazeed Image Click'
                },
            });
        }
        else if(id == 1){
            TagManager.dataLayer({
                dataLayer: {
                    event: 'Our-Clients Mark Image Click'
                },
            });
        }
        else if(id == 2){
            TagManager.dataLayer({
                dataLayer: {
                    event: 'Our-Clients Samuel Image Click'
                },
            });
        }
        else if(id == 3){
            TagManager.dataLayer({
                dataLayer: {
                    event: 'Our-Clients Rohit Image Click'
                },
            });
        }
        else if(id == 4){
            TagManager.dataLayer({
                dataLayer: {
                    event: 'Our-Clients Manav Image Click'
                },
            });
        }
        else if(id == 5){
            TagManager.dataLayer({
                dataLayer: {
                    event: 'Our-Clients Shiv Image Click'
                },
            });
        }
    }
    const handleClickPlayButton =(id)=>{
        if(id == 0){
            TagManager.dataLayer({
                dataLayer: {
                    event: 'Our-Clients Yazeed Play Button Click'
                },
            });
        }
        else if(id == 1){
            TagManager.dataLayer({
                dataLayer: {
                    event: 'Our-Clients Mark Play Button Click'
                },
            });
        }
        else if(id == 2){
            TagManager.dataLayer({
                dataLayer: {
                    event: 'Our-Clients Samuel Play Button Click'
                },
            });
        }
        else if(id == 3){
            TagManager.dataLayer({
                dataLayer: {
                    event: 'Our-Clients Rohit Play Button Click'
                },
            });
        }
        else if(id == 4){
            TagManager.dataLayer({
                dataLayer: {
                    event: 'Our-Clients Manav Play Button Click'
                },
            });
        }
        else if(id == 5){
            TagManager.dataLayer({
                dataLayer: {
                    event: 'Our-Clients Shiv Play Button Click'
                },
            });
        }
    }
  return (
    <section className='let_speak_client'>
        <div className="container">
            <div className="row">
                <div className="col-md-12" data-aos="fade-right">
                    <div className="title">Let our clients speak</div>
                    <div className="desc">Our Smile is powered by our customers.</div>
                </div>
                {
                    clientsjson.map((data, id) =>(
                        <div className="col-md-6 col-lg-4" key={id} data-aos="flip-right"  data-aos-delay={id * 400}>
                        <div className="client_box">
                            <div className="client_img">
                            <a href={`${data.youtube}`} target="_blank" onClick={ ()=>handleClickImage(id)}>
                                <img src={`${process.env.PUBLIC_URL}${data.img}`} alt="" />
                                </a>
                                <a href={`${data.youtube}`} target="_blank" onClick={ ()=>handleClickPlayButton(id)}>
                                <div className="button">
                                    <img src={Ply} alt="" />
                                    </div>
                                </a>
                            </div>
                            <div className="content">
                                <div className="avtar">
                                    <img src={`${process.env.PUBLIC_URL}${data.flag}`} alt="" />
                                </div>
                            <div className="details">
                                <div className="name">{data.name}</div>
                                <div className="address">{data.company} - {data.address}</div>
                            </div>
                        </div>
                    </div>
                    </div>
                    ))
                }
              
            </div>
        </div>
    </section>
  )
}
