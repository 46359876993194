import React,{useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Description() {
  useEffect(()=>{
    AOS.init({
      duration: 1500,
    });
  },[])
  return (
    <div className='desc_section'>
        <div className="container" data-aos="fade-up">
        Stay ahead of the curve with our Mobile & Web App Development,AI Integrations and Innovative UI/UX design Services. 
        </div>
    </div>
  )
}
