import React,{useEffect} from 'react'
import './AboutUs.scss'
import Navigation from '../../Components/Navigation/Navigation'
import Footer from '../../Components/Footer/Footer'
import Heading from '../../Components/Heading/Heading'
import Content from './Components/Content'
// import WhyChooseUs from '../Home/Components/WhyChooseUs'
import WhyChooseUs from '../Home/Components/WhyChooseUs'
import Contect from '../Home/Components/Contect'
import Team from './Components/Team'
import OurClients from '../Home/Components/Clients'
import AOS from 'aos';
import 'aos/dist/aos.css'
import ReactGA from 'react-ga';
import Clients from '../Home/Components/Clients'

export default function AboutUs() {
  useEffect(()=>{
    AOS.init({
      duration: 3000,
    });
  },[])
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <>
        <Navigation offer={true} />
        <Heading Title="About us" MiniTitle="" Links="About us"/>
        <Content/>
        <WhyChooseUs/>
        <Team/>
        <Clients />
        {/* <OurClients/> */}
        <Contect/>
        <Footer/>
    </>
  )
}
