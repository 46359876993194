import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import jsondata from "./../../../Data/Case_Studies.json";
export default function Details({ id, selectedApp }) {
  return (
    <section className="details_container">
      <div className="container">
        <div className="page_links">
          <Link to="/">Home</Link> &gt; <span>Case Studies</span>
        </div>
        <div className="row">
          <div className="col-md-6" data-aos="fade-right" data-aos-duration="1=3000">
            <div className="content">
              <div className="logo">
                <img src={`${process.env.PUBLIC_URL}${selectedApp.logo}`} alt="" />
              </div>
              <div className="title">{selectedApp.title}</div>
              <div className="desc">{selectedApp.description}</div>
              <div className="platform">
                <span className="web">Website</span>
                <span className="ios">iOS</span>
                <span className="app">Android</span>
              </div>
              <div className="industries">
                <span className="ind_head">Industries</span>
                {selectedApp.industries.industries_all &&
                  selectedApp.industries.industries_all.length > 0 &&
                  selectedApp.industries.industries_all.map((data, key) => (
                      <ul className="ind_ul">
                      <li className="ind_data">{data}</li>
                      </ul>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="detail_image" data-aos="fade-left">
              <img src={`${process.env.PUBLIC_URL}${selectedApp.detail_image}`} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
