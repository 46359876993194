import React,{useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Technologies() {
  
  const ServicesList = [
  {
    id:1,
    title: 'Mobile App Development',
    desc: 'Enhancing Businesses Profitability Through State of The Art iPhone, Android App And Website Development',
    services: [
        {
            id: 1,
            icon: 'images/lifestyle/icons/M-1.svg',
            name: 'Android APP Development'
        },
        {
            id: 2,
            icon: 'images/lifestyle/icons/M-2.svg',
            name: 'iPhone APP Development'
        },
        {
            id: 3,
            icon: 'images/lifestyle/icons/M-3.svg',
            name: 'IPAD App Development'
        },
        {
            id: 4,
            icon: 'images/lifestyle/icons/M-4.svg',
            name: 'React native Development'
        },
        {
            id: 5,
            icon: 'images/lifestyle/icons/M-5.svg',
            name: 'Flutter APP Development'
        },
        {
            id: 6,
            icon: 'images/lifestyle/icons/M-6.svg',
            name: 'WearOs app Development'
        },
        {
            id: 7,
            icon: 'images/lifestyle/icons/M-7.svg',
            name: 'IOT app App Development'
        }
    ]
  },
  {
    id:2,
    title: 'Custom Software Development',
    desc: 'We are working on enterprise - level blockchain apps with our expert team.',
    class: 'white',
    services: [
        {
            id: 1,
            icon: 'images/lifestyle/icons/C-1.svg',
            name: 'PHP Software Development'
        },
        {
            id: 2,
            icon: 'images/lifestyle/icons/C-2.svg',
            name: 'MERN Software Development'
        },
        {
            id: 3,
            icon: 'images/lifestyle/icons/C-3.svg',
            name: 'Cloud SOftware Development'
        },
        {
            id: 4,
            icon: 'images/lifestyle/icons/C-4.svg',
            name: 'Online SOftware Development'
        },
        {
            id: 5,
            icon: 'images/lifestyle/icons/C-5.svg',
            name: 'Amazon / Windows Cloud '
        }
    ]
  },
  {
    id:4,
    title: 'UI/UX Design',
    desc: 'Enhancing Businesses Profitability Through State of The Art iPhone, Android App And Website Development',
    services: [
        {
            id: 1,
            icon: 'images/lifestyle/icons/U-1.svg',
            name: 'GRaphic Design'
        },
        {
            id: 2,
            icon: 'images/lifestyle/icons/U-2.svg',
            name: 'Mobile APP Design'
        },
        {
            id: 3,
            icon: 'images/lifestyle/icons/U-3.svg',
            name: 'Web App Development'
        },
        {
            id: 4,
            icon: 'images/lifestyle/icons/U-4.svg',
            name: 'HTML Design'
        },
        {
            id: 5,
            icon: 'images/lifestyle/icons/U-5.svg',
            name: 'Brochure Design'
        },
        {
            id: 6,
            icon: 'images/lifestyle/icons/U-6.svg',
            name: 'Logo Design'
        }
    ]
  },
  {
    id:3,
    title: 'Blockchain Development',
    desc: 'We are working on enterprise - level blockchain apps with our expert team.',
    class: 'white',
    services: [
        {
            id: 1,
            icon: 'images/lifestyle/icons/B-1.svg',
            name: 'NFT Market Place'
        },
        {
            id: 2,
            icon: 'images/lifestyle/icons/B-8.svg',
            name: 'DAPPs'
        },
        {
            id: 3,
            icon: 'images/lifestyle/icons/B-3.svg',
            name: 'DEFI'
        },
        {
            id: 4,
            icon: 'images/lifestyle/icons/B-4.svg',
            name: 'SMart Contract'
        },
        {
            id: 5,
            icon: 'images/lifestyle/icons/B-5.svg',
            name: 'DEX /SWAP'
        },
        {
            id: 6,
            icon: 'images/lifestyle/icons/B-6.svg',
            name: 'Launchpad Development'
        },
        {
            id: 7,
            icon: 'images/lifestyle/icons/B-7.svg',
            name: 'Crypto/ BLockchain'
        }
    ]
  }
 
  ]
  useEffect(()=>{
    AOS.init({
        duration: 1000,
    })
  },[])

    return (
        <>
        {
            ServicesList.map((item)=>(
                <section className={`technologies ${item?.class}`}>
                    <div className="container">
                        <div className="heading" data-aos="fade-right">
                            <div className="title">{item?.title}</div>
                            <div className="desc">{item?.desc}</div>
                        </div>
                        <div className="row">
                            {
                                item.services.map((service)=>(
                                    <div className="col-md-12 col-lg-6 col-xl-4" key={service?.id}  data-aos="fade-up"  data-aos-delay={item * 3000}>
                                        <div className="techno_box">
                                            <div className="tech_icon"><img src={`${process.env.PUBLIC_URL}${service?.icon}`} alt="" /></div>
                                            <div className="tech_name">{service?.name}</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>        
                </section>
            ))
        }
    </>
    )
}
