import React from 'react'
import Serve from './../../../assets/images/Serve.svg'
import icon1 from './../../../assets/images/industries_icons/icon-1.svg'
import icon2 from './../../../assets/images/industries_icons/icon-2.svg'
import icon3 from './../../../assets/images/industries_icons/icon-3.svg'
import icon4 from './../../../assets/images/industries_icons/icon-4.svg'
import icon5 from './../../../assets/images/industries_icons/icon-5.svg'
import icon6 from './../../../assets/images/industries_icons/icon-6.svg'
import icon7 from './../../../assets/images/industries_icons/icon-7.svg'
import icon8 from './../../../assets/images/industries_icons/icon-8.svg'
import icon9 from './../../../assets/images/industries_icons/icon-9.svg'
import icon10 from './../../../assets/images/industries_icons/icon-10.svg'
import icon11 from './../../../assets/images/industries_icons/icon-11.svg'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'
export default function WeServe() {

    useEffect(()=>{
        AOS.init({
            duration: 1500,
        });
    },[])

  return (
    <section className='we_serve'>
        <div className="container">
            <div className="row">
                <div className="col-md-12" data-aos="fade-right">
                    <div className="title">Industries We Serve</div>
                    <div className="desc">Enhancing Businesses Profitability Through State of The Art Iphone, Android App And Website Development</div>
                </div>
                <div className="col-md-6 col-lg-4"  data-aos="fade-up">
                    <div className="serve_box box1">
                        <div className="icon">
                            <img src={icon1} alt="" />
                        </div>
                        <div className="text">Software Development</div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4" data-aos="fade-up">
                    <div className="serve_box box2">
                        <div className="icon">
                            <img src={icon2} alt="" />
                        </div>
                        <div className="text">Medical & Healthcare</div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4" data-aos="fade-up">
                    <div className="serve_box box3">
                        <div className="icon">
                            <img src={icon3} alt="" />
                        </div>
                        <div className="text">Fitness Industries</div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4" data-aos="fade-up">
                    <div className="serve_box box4">
                        <div className="icon">
                            <img src={icon4} alt="" />
                        </div>
                        <div className="text">Driving Industry</div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4" data-aos="fade-up">
                    <div className="serve_box box5">
                        <div className="icon">
                            <img src={icon5} alt="" />
                        </div>
                        <div className="text">Garment Industry</div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4" data-aos="fade-up">
                    <div className="serve_box box6">
                        <div className="icon">
                            <img src={icon6} alt="" />
                        </div>
                        <div className="text">Home care Industries</div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4" data-aos="fade-up">
                    <div className="serve_box box7">
                        <div className="icon">
                            <img src={icon7} alt="" />
                        </div>
                        <div className="text">Insurance</div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4" data-aos="fade-up">
                    <div className="serve_box box8">
                        <div className="icon">
                            <img src={icon8} alt="" />
                        </div>
                        <div className="text">Ecommerce</div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4" data-aos="fade-up">
                    <div className="serve_box box9">
                        <div className="icon">
                            <img src={icon9} alt="" />
                        </div>
                        <div className="text">Online Meeting</div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4" data-aos="fade-up">
                    <div className="serve_box box10">
                        <div className="icon">
                            <img src={icon10} alt="" />
                        </div>
                        <div className="text">SOcial media App</div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4" data-aos="fade-up">
                    <div className="serve_box box11">
                        <div className="icon">
                            <img src={icon11} alt="" />
                        </div>
                        <div className="text">Dating Apps</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
