import React,{useEffect} from 'react'

export default function Technologies({id, selectedApp}) {
    return (
    <section className='technologies'>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="title"  data-aos="zoom-in-right">Technologies Used</div>
                    <div className="desc"  data-aos="zoom-in-right">Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor tempor incididunt ut labore dolore magna.</div>
                    <div className="tech_wrapper">
                        {
                            selectedApp?.technologies_used?.technology&&
                            selectedApp.technologies_used.technology.length > 0 &&
                            selectedApp.technologies_used.technology.map((a, b) =>(     
                                <div className="tech_box" key={b} data-aos="zoom-in-left" data-aos-delay={b * 500}><img src={`${process.env.PUBLIC_URL}${a}`} alt="" /></div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
