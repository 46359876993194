import React from "react";
import Avtar from "./../../../assets/images/avtar.png";
import Ply from "./../../../assets/images/ply.svg";
import Left from "./../../../assets/images/left.svg";
import Right from "./../../../assets/images/right.svg";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";
import { Link } from "react-router-dom";
import clientsjson from "./../../../Data/ClientsSpeak.json";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import TagManager from "react-gtm-module";

export default function ClientSpeak() {
  const carouselRef = React.useRef(null);

  const options = {
    // items: ,
    nav: true,
    dots: true,
    loop: false,
    autoplay: false,
    margin: 30,
    navText: [
      '<button><svg width="39" height="39" viewBox="0 0 39 39" xmlns="http://www.w3.org/2000/svg"><path d="M19.5 38.25C23.2084 38.25 26.8335 37.1503 29.9169 35.0901C33.0004 33.0298 35.4036 30.1014 36.8227 26.6753C38.2419 23.2492 38.6132 19.4792 37.8897 15.8421C37.1662 12.2049 35.3805 8.86398 32.7582 6.24175C30.136 3.61951 26.7951 1.83375 23.1579 1.11028C19.5208 0.386803 15.7508 0.758114 12.3247 2.17726C8.89856 3.5964 5.97021 5.99964 3.90994 9.08306C1.84966 12.1665 0.749992 15.7916 0.749992 19.5C0.755451 24.4711 2.73265 29.2371 6.24777 32.7522C9.76289 36.2673 14.5289 38.2445 19.5 38.25ZM19.5 35.4375C16.3479 35.4375 13.2665 34.5028 10.6456 32.7515C8.02468 31.0003 5.98193 28.5112 4.77566 25.599C3.56939 22.6868 3.25377 19.4823 3.86872 16.3907C4.48368 13.2992 6.00158 10.4594 8.23048 8.23048C10.4594 6.00158 13.2992 4.48368 16.3907 3.86873C19.4823 3.25378 22.6868 3.5694 25.599 4.77567C28.5112 5.98194 31.0003 8.02469 32.7515 10.6456C34.5028 13.2665 35.4375 16.3479 35.4375 19.5C35.4325 23.7254 33.7518 27.7762 30.764 30.764C27.7762 33.7518 23.7254 35.4325 19.5 35.4375ZM14.5387 20.34L14.6756 20.4994L21.2119 27C21.4637 27.2481 21.7998 27.3922 22.1531 27.4037C22.5064 27.4151 22.8511 27.2931 23.1185 27.0619C23.3859 26.8306 23.5564 26.5072 23.5961 26.1559C23.6357 25.8046 23.5416 25.4513 23.3325 25.1662L23.1956 25.0069L17.6625 19.5L23.1956 13.9875C23.4347 13.7501 23.5804 13.4346 23.6062 13.0986C23.6321 12.7627 23.5362 12.4287 23.3362 12.1575L23.2012 12C22.9636 11.7626 22.649 11.618 22.3141 11.5922C21.9792 11.5664 21.6461 11.6612 21.375 11.8594L21.2119 12L14.6756 18.5081C14.4365 18.7464 14.2911 19.0628 14.2659 19.3995C14.2408 19.7362 14.3376 20.0707 14.5387 20.3419V20.34Z"/></svg></button>',
      '<button><svg width="39" height="39" viewBox="0 0 39 39" xmlns="http://www.w3.org/2000/svg"><path d="M19.5 38.25C15.7916 38.25 12.1665 37.1503 9.08307 35.0901C5.99964 33.0298 3.59641 30.1014 2.17727 26.6753C0.758124 23.2492 0.386812 19.4792 1.11028 15.8421C1.83376 12.2049 3.61952 8.86398 6.24176 6.24175C8.86399 3.61951 12.2049 1.83375 15.8421 1.11028C19.4792 0.386803 23.2492 0.758114 26.6753 2.17726C30.1014 3.5964 33.0298 5.99964 35.0901 9.08306C37.1503 12.1665 38.25 15.7916 38.25 19.5C38.2445 24.4711 36.2674 29.2371 32.7522 32.7522C29.2371 36.2673 24.4711 38.2445 19.5 38.25ZM19.5 35.4375C22.6521 35.4375 25.7335 34.5028 28.3544 32.7515C30.9753 31.0003 33.0181 28.5112 34.2243 25.599C35.4306 22.6868 35.7462 19.4823 35.1313 16.3907C34.5163 13.2992 32.9984 10.4594 30.7695 8.23048C28.5406 6.00158 25.7008 4.48368 22.6093 3.86873C19.5177 3.25378 16.3132 3.5694 13.401 4.77567C10.4888 5.98194 7.9997 8.02469 6.24846 10.6456C4.49723 13.2665 3.56251 16.3479 3.56251 19.5C3.56747 23.7254 5.24819 27.7762 8.23597 30.764C11.2238 33.7518 15.2746 35.4325 19.5 35.4375ZM24.4613 20.34L24.3244 20.4994L17.7881 27C17.5363 27.2481 17.2002 27.3922 16.8469 27.4037C16.4936 27.4151 16.1489 27.2931 15.8815 27.0619C15.6141 26.8306 15.4436 26.5072 15.4039 26.1559C15.3643 25.8046 15.4584 25.4513 15.6675 25.1662L15.8044 25.0069L21.3375 19.5L15.8044 13.9875C15.5653 13.7501 15.4196 13.4346 15.3938 13.0986C15.3679 12.7627 15.4638 12.4287 15.6638 12.1575L15.7988 12C16.0364 11.7626 16.351 11.618 16.6859 11.5922C17.0208 11.5664 17.3539 11.6612 17.625 11.8594L17.7881 12L24.3244 18.5081C24.5635 18.7464 24.7089 19.0628 24.7341 19.3995C24.7592 19.7362 24.6624 20.0707 24.4613 20.3419V20.34Z"/></svg></button>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1000: {
        items: 3,
      },
      1400: {
        items: 3,
      },
    },
  };
  const handleNextClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Home Client-Speak Next Button Click",
      },
    });
    carouselRef.current.next();
  };

  const handlePrevClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Home Client-Speak Previous Button Click",
      },
    });
    carouselRef.current.prev();
  };
  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);

  const handleClickPlayButton = (id) => {
    if (id == 0) {
      TagManager.dataLayer({
        dataLayer: {
          event: "Home Client-Speak Yazeed Play Button Click",
        },
      });
    } else if (id == 1) {
      TagManager.dataLayer({
        dataLayer: {
          event: "Home Client-Speak Mark Play Button Click",
        },
      });
    } else if (id == 2) {
      TagManager.dataLayer({
        dataLayer: {
          event: "Home Client-Speak Samuel Play Button Click",
        },
      });
    } else if (id == 3) {
      TagManager.dataLayer({
        dataLayer: {
          event: "Home Client-Speak Rohit Play Button Click",
        },
      });
    } else if (id == 4) {
      TagManager.dataLayer({
        dataLayer: {
          event: "Home Client-Speak Manav Play Button Click",
        },
      });
    } else if (id == 5) {
      TagManager.dataLayer({
        dataLayer: {
          event: "Home Client-Speak Shiv Play Button Click",
        },
      });
    }
  };

  const handleClickImage = (id) => {
    if (id == 0) {
      TagManager.dataLayer({
        dataLayer: {
          event: "Home Client-Speak Yazeed Image Click",
        },
      });
    } else if (id == 1) {
      TagManager.dataLayer({
        dataLayer: {
          event: "Home Client-Speak Mark Image Click",
        },
      });
    } else if (id == 2) {
      TagManager.dataLayer({
        dataLayer: {
          event: "Home Client-Speak Samuel Image Click",
        },
      });
    } else if (id == 3) {
      TagManager.dataLayer({
        dataLayer: {
          event: "Home Client-Speak Rohit Image Click",
        },
      });
    } else if (id == 4) {
      TagManager.dataLayer({
        dataLayer: {
          event: "Home Client-Speak Manav Image Click",
        },
      });
    } else if (id == 5) {
      TagManager.dataLayer({
        dataLayer: {
          event: "Home Client-Speak Shiv Image Click",
        },
      });
    }
  };


  return (
    <section className="client_speak">
      <div className="container">
        <div className="row">
          <div className="col-md-12" data-aos="fade-up">
            <div className="heading">
              <h2>Let our clients speak</h2>
              <div className="desc">
                <p>Our Smile is powered by our customers.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="client_carousel">
          <div className="client_wrapper">
            <div className="slide_action">
            <button onClick={handlePrevClick}>
              <img src={Left} alt="" />
            </button>
          </div>
            {/* <OwlCarousel options={options} ref={carouselRef}>
            {clientsjson.map((data, id) => (
              <div className="client_box" key={id}>
                <a
                  href={`${data.youtube}`}
                  target="_blank"
                  onClick={() => handleClickImage(id)}
                >
                  <div className="client_img">
                    <img src={`${process.env.PUBLIC_URL}${data.img}`} alt="" />
                  </div>
                </a>
                <div className="content">
                  <a
                    href={`${data.youtube}`}
                    target="_blank"
                    onClick={() => handleClickPlayButton(id)}
                  >
                    <div className="button">
                      <img src={Ply} alt="" />
                    </div>
                  </a>
                  <div className="avtar">
                    <img src={`${process.env.PUBLIC_URL}${data.flag}`} alt="" />
                  </div>
                  <div className="details">
                    <div className="name">{data.name}</div>
                    <div className="address">
                      {data.company} - {data.address}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel> */}
            <OwlCarousel options={options} ref={carouselRef}>
            {clientsjson.map((data, id) => (
              <div className="client_box" key={id}>
                <a
                  href={`${data.youtube}`}
                  target="_blank"
                  onClick={() => handleClickImage(id)}
                >
                  <div className="client_img">
                    <img src={`${process.env.PUBLIC_URL}${data.img}`} alt="" />
                  </div>
                </a>
                <div className="content">
                  <a
                    href={`${data.youtube}`}
                    target="_blank"
                    onClick={() => handleClickPlayButton(id)}
                  >
                    <div className="button">
                      <img src={Ply} alt="" />
                    </div>
                  </a>
                  <div className="details">
                    <div className="name">{data.name}</div>
                    <div className="address">
                      {data.company} - {data.address}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
            <div className="slide_action">
            <button onClick={handleNextClick}>
              <img src={Right} alt="" />
            </button>
          </div>
          </div>
        </div>
      </div>
    </section>
  );
}
