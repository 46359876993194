import React, { useEffect } from "react";
import "./ContactMe.scss";
import Add from "./../../assets/images/add.svg";
import Mail from "./../../assets/images/mail.svg";
import Call from "./../../assets/images/call.svg";
import AOS from "aos";
import "aos/dist/aos.css";

export default function ContactMe() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="contact_me">
      <div className="title">Contact Me</div>
      <div className="tag">Have a any project in mind!</div>

      <div
        className="contact_box address"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <div className="icon">
          <img src={Add} alt="" />
        </div>
        <div className="text">
          A-405, PNTC (Prahlad Nagar Trade Center), Times of India PressRoad,
          B/H Titanium City Center, Vejalpur, Ahmedabad – 380015
        </div>
      </div>
      <div
        className="contact_box mail"
        data-aos="fade-right"
        data-aos-duration="1500"
      >
        <div className="icon">
          <img src={Mail} alt="" />
        </div>
        <div className="text">sales@koolmind.in</div>
      </div>
      <div
        className="contact_box call"
        data-aos="fade-right"
        data-aos-duration="2000"
      >
        <div className="icon">
          <img src={Call} alt="" />
        </div>
        <div className="text d-block">
          <span>+91-6352405242</span>
          <br />
          <span>+91-8320079300</span>
        </div>
      </div>
    </div>
  );
}
