import React, { useEffect, useState } from "react";
import "./OurWorks.scss";
import Navigation from "./../../Components/Navigation/Navigation";
import Footer from "./../../Components/Footer/Footer";
import Details from "./Components/Details";
import Requirement from "./Components/Requirement";
import Challenges from "./Components/Challenges";
import UseCase from "./Components/UseCase";
import Results from "./Components/Results";
import VisualInterface from "./Components/VisualInterface";
import Technologies from "./Components/Technologies";
import LetsTalk from "../../Components/LetsTalk/LetsTalk";
import Talk from "./Components/Talk";
import { useParams } from "react-router-dom";
import JSONFIle from "../../Data/Case_Studies.json";
import Solution from "./Components/Solution";
import AOS from "aos";
import "aos/dist/aos.css";

export default function OurWorks() {
  const [id, setId] = useState({});
  const [selectedApp, setSelectedApp] = useState({});
  let { route_title } = useParams();
  useEffect(() => {
    if (route_title) {
      setSelectedApp(JSONFIle.filter((f) => f.route_title === route_title)[0]);
    }
  }, [route_title]);

  useEffect(() => {
    if (Object.keys(selectedApp).length > 0) {
      setId(selectedApp.id);
    }
  }, [selectedApp]);

  useEffect(() => {
    AOS.init({
      duration: 3000,
    });
  }, []);
  return (
    <>
      {selectedApp && Object.keys(selectedApp).length > 0 && (
        <>
          <Navigation offer={true} />
          <Details id={id} selectedApp={selectedApp} />
          <Requirement id={id} selectedApp={selectedApp} />
          <Challenges id={id} selectedApp={selectedApp} />
          <Solution id={id} selectedApp={selectedApp} />
          <UseCase id={id} selectedApp={selectedApp} />
          <Results id={id} selectedApp={selectedApp} />
          <VisualInterface id={id} selectedApp={selectedApp} />
          <Technologies id={id} selectedApp={selectedApp} />
          <Talk id={id} selectedApp={selectedApp} />
          <Footer />
        </>
      )}
    </>
  );
}
