import React from 'react'
import './../AboutUs.scss'

export default function AboutHeading(props) {
    const {Title, Image} = props
  return (
    <section className='about_heading'>
        <div className="about_img_wrapper">
            <img src={Image} alt="" />
        </div>
        <div className="container" data-aos="zoom-out-right">
            <div className="heading_title_wrapper">
                <div className="heading_title">
                    <span>{Title}</span>
                </div>
            </div>
        </div>
    </section>
  )
}
