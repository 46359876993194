import React,{useState, useEffect} from "react";
import GoBlue from "./../../../assets/images/blue_go.svg";
import { Link, useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import TagManager from "react-gtm-module";

export default function Clients() {
  const { pathname } = useLocation();
  console.log(pathname);
  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }

  const logoFolderImages = importAll(
    require.context(
      `../../../../public/images/logos2`,
      false,
      /\.(png|jpe?g|svg)$/
    )
  );

  useEffect(() => {
    AOS.init({
      duration: 3000,
    });
  }, []);

  const handleViewAllClients = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Home View All Clients Click",
      },
    });
  };

  return (
    <section
      className="clients"
      style={{ paddingTop: (pathname === "/about" || pathname === "/our-clients") ? "120px" : 0 }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="heading" data-aos="fade-up">
              <div className="title">Our Clients</div>
              <div className="des">We Work With Global Top Brands.</div>
            </div>
          </div>
          <div className="col-md-6">
            <div
              className="client_desc"
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
            >
              <p>
                We win our client's trust for the work they provided us. We have
                a 89% repititive client ratio. We make almost every genre of
                applications. You name it and we build it.
              </p>
            </div>
          </div>
          {/* <div className="col-md-1"></div> */}
          <div className="col-md-12">
            {/* <div className="client_wrapper">
              {logoFolderImages &&
                Object.values(logoFolderImages).length > 0 &&
                Object.values(logoFolderImages).map((v, i) => {
                  if (i >= 12) {
                    return;
                  } else {
                    return (
                      <div className="client_logo" data-aos="zoom-in-left">
                        <img src={v} alt="" />
                      </div>
                    )
                  }
                })}
            </div> */}
            <div className="row client_wrapper">
              {logoFolderImages &&
                Object.values(logoFolderImages).length > 0 &&
                Object.values(logoFolderImages).map((v, i) => {
                  if (pathname === "/about" || pathname === "/our-clients") {
                    return (
                      <div className="col-lg-2 col-md-3 col-sm-3 col-xs-4 col-4 mb-3">
                        <div className="client_logo w-100" data-aos="zoom-in-left">
                          <img className="w-100" src={v} alt="" />
                        </div>
                      </div>
                    );
                  } else if (i >= 12) {
                    return;
                  } else {
                    return (
                      <div className="col-lg-2 col-md-3 col-sm-3 col-xs-4 col-4 mb-3">
                        <div className="client_logo w-100" data-aos="zoom-in-left">
                          <img className="w-100" src={v} alt="" />
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
          {/* <div className="col-md-1"></div> */}
          {/* <div className="col-md-12">
            <div className="client_desc" data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom">
              <p>
                We win our client's trust for the work they provided us. We have
                a 89% repititive client ratio. We make almost every genre of
                applications. You name it and we build it.
              </p>
            </div>
            <div className="view_all">
              <Link to="our-clients" onClick={handleViewAllClients}>
                View All Clients <img src={GoBlue} alt="" />
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}
