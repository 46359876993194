import React,{useEffect} from 'react'
import AboutImg from './../../../assets/images/about.jpg'
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Content() {
    const options = {
        items: 1,
        nav: false,
        rewind: false,
        dots: true,
        loop: false,
        autoplay: true,
        margin:0,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:1
            }
        }
      };
      useEffect(()=>{
        AOS.init({
          duration: 1500,
        });
      },[])
  return (
    <section className='client_content'>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="title" data-aos="fade-up">We provide solutions for removing the pain point of our client's business.</div>
                    <div className="desc" data-aos="fade-up">Transforming business digitally is not the only solution of growth for business. You need to maintain and upgrade your digital resources periodically and we are here to help you with successfull results.</div>
                    {/* <div className="about_image">
                        <OwlCarousel options={options} >
                            <img src={AboutImg} alt="" />
                            <img src={AboutImg} alt="" />
                            <img src={AboutImg} alt="" />
                            <img src={AboutImg} alt="" />
                        </OwlCarousel>
                    </div> */}
                </div>
            </div>
        </div>
    </section>
  )
}
