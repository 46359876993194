import React from 'react'
import Call from './../../assets/images/Calling.svg'
import './Navigation.scss'

export default function BestOffer() {
  return (
    <>
      {/* <section className="best_offer">
        <div className="container">
          <div className="offer_wrapper">
            <div className="offer_text">Best Solution we offer you</div>
            <div className="call">
              <div className="call_wrapper">
                <img src={Call} alt="" />
                <span>+91-8320079300</span>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  )
}
