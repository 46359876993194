import React,{useEffect} from "react";
import LetsTalk from "../../../Components/LetsTalk/LetsTalk";
import Play from "./../../../assets/images/ply.svg";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Talk({ id, selectedApp }) {
  useEffect(()=>{
    AOS.init({
        duration: 3000,
  });
  },[])
  return (
    <section className="talk">
      <div className="container">
        <div className="contant-flex">
          <div className="row">
            {/* <div className="col-md-12 col-lg-7"> */}
            <div
              className={`col-md-12 ${
                selectedApp.client_info.isClient ? "col-lg-7" : ""
              }`}
            >
              <div className="title" data-aos="fade-right">Let’s talk!</div>
              <div className="desc" data-aos="fade-right">
                We know, you have an excellent idea. Let's have an awesome
                conversation & get your idea live sooner!
              </div>
              <LetsTalk title={false} />
            </div>
            {/* </div> */}
            {/* <div className="row"> */}
            {selectedApp.client_info.isClient && (
                <div className="col-md-12 col-lg-5 " >
              <div className="client_wrapper"  data-aos="fade-left">
                  <div className="cli_title">
                    <h2>Client Review</h2>
                    </div>
                  <div className="video_wrapper">
                    <div className="thumbnail">
                      <a href={`${selectedApp.client_info.youtube}`} target="_blank">
                        <img src={`${process.env.PUBLIC_URL}${selectedApp.client_info.client_img}`} alt=""/>
                        <div className="play">
                          <button>
                            <img src={Play} alt="" />
                          </button>
                        </div>
                      </a>
                    </div>
                    <div className="video_details">
                      <div className="video_title">
                        {selectedApp.client_info.client_name}
                      </div>
                      <div className="video_dec">
                        {selectedApp.title} -{" "}
                        {selectedApp.client_info.client_add}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* </div> */}
        </div>
      </div>
    </section>
  );
}
