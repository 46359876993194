import React from 'react'
import JSONFIle from "./../../../Data/Case_Studies.json";


export default function Requirement({id, selectedApp}) {
  // debugger
  return (
    <section className='requirement'>
        <div className="container">
            <div className="requirement_wrapper" data-aos="fade-up">
                <div className="title">Requirement</div>
                <div className="desc">{selectedApp.requirement}</div>
            </div>
        </div>
    </section>
  )
}
