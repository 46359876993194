import React,{useEffect} from 'react'
import Navigation from '../../../Components/Navigation/Navigation'
import Footer from '../../../Components/Footer/Footer'
import Contect from '../../Home/Components/Contect'
import AboutHeading from './AboutHeading'
import Image from './../../../assets/images/storypagebanner.jpeg'
import Story from './../../../assets/images/story.png'
import Logo from "./../../../assets/images/biglogo.svg";
import AOS from 'aos';
import 'aos/dist/aos.css'

export default function OurStory() {

    useEffect(()=>{
        AOS.init({
            duration: 1000,
        });
    },[])
  return (
    <>
    <Navigation offer={true}/>
    <AboutHeading Title='The story of Koolmind' Image={Image}/>
    <div className="story_wrapper">
        <div className="container">
            <div className="story_container">
                <div className="row">
                    <div className="col-md-5">
                        <div className="top_img" data-aos="fade-up"><img src={Story} alt="" /></div>
                        <div className="story_logo" data-aos="zoom-out" ><img src={Logo} alt="" /></div>
                    </div>
                    <div className="col-md-7">
                        <div className="story_content">
                            <div className="title mb_45" data-aos="fade-up">The story of Koolimind started with a frustration - my frustration. As a seasoned IT professional, I had seen firsthand how projects often failed due to a lack of accountability and responsibility. Too often, developers would simply blame each other or the client for problems that arose, rather than taking ownership of their work and working collaboratively to find solutions.</div>
                            <div className="paragraph" data-aos="fade-up">This lack of responsibility was not just frustrating for me, but for clients and other stakeholders in the projects we worked on. It led to a lot of “heated minds”. As we discussed these issues with our clients, it became clear that they were experiencing the same frustrations, and were looking for a partner who would take responsibility for the success of their projects.</div>
                            <div className="paragraph" data-aos="fade-up">This shift in mindset wasn't easy, but it was necessary. Over time, we began to see the benefits of this approach. Our clients were more satisfied with our work, and our team members were more engaged and motivated. By taking responsibility for our work, we were able to deliver better results and build stronger relationships with our clients.</div>
                            <div className="paragraph" data-aos="fade-up">While starting KoolMind, we knew we had to do better. We set out to create a culture of accountability and ownership at Koolimind, where every team member was empowered to take responsibility for their work and work collaboratively with others to deliver outstanding results. We encourage our team members to take ownership of their work and to be proactive in finding solutions to problems, rather than just blaming others. The aim was to diffuse the tensions arising due to lack of responsibility, the aim was to create more cool minds. </div>
                            <div className="mini_title" data-aos="fade-up">Hence I named the company KoolMind</div>
                            {/* <div className="paragraph" data-aos="fade-up">This shift in mindset wasn't easy, but it was necessary. Over time, we began to see the benefits of this approach. Our clients were more satisfied with our work, and our team members were more engaged and motivated. By taking responsibility for our work, we were able to deliver better results and build stronger relationships with our clients.</div> */}
                            <div className="title" data-aos="fade-up">Today, at Koolmind, responsibility and ownership are core values that guide everything we do. We're proud of the culture we've built, and we're committed to continuing to take ownership of our work and to deliver outstanding results for our clients.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Contect/>
    <Footer/>
    </>
  )
}
