import React from 'react'
import './Industries.scss'
import Navigation from '../../Components/Navigation/Navigation'
import Footer from '../../Components/Footer/Footer'
import Heading from '../../Components/Heading/Heading'
import WhyChooseUs from './../Home/Components/WhyChooseUs'
import Contect from './../Home/Components/Contect'
// import Content from './Components/Content'
import IndustriesDescription from './../Industries/Components/IndustriesDescription'
import WeServe from './Components/WeServe'

export default function Industries() {
  return (
    <>
    <Navigation offer={true}/>
    <Heading Title="Industries" MiniTitle="" Links="Industries" />
    <IndustriesDescription/>
    <WeServe/>
    <WhyChooseUs/>
    <Contect/>
    <Footer/>
    </>
    )
}
