import React from 'react'
import ContactMe from '../../../Components/ContactMe/ContactMe'
import LetsTalk from '../../../Components/LetsTalk/LetsTalk'

export default function Contect() {
  return (
    <section className='home_contect'>
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-lg-6">
                    <ContactMe/>
                </div>
                <div className="col-md-12 col-lg-6">
                    <LetsTalk title={true}/>
                </div>
            </div>
        </div>
    </section>
  )
}