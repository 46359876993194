import React,{useEffect} from "react";
import Go from "./../../../assets/images/blue_go.svg";
import OurWorkJson from "./../../../Data/OurWork.json";
import { Link, useParams } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function WorkBox() {
  let {id} = useParams();
  useEffect(()=>{
    AOS.init({
      duration: 1500,
    });
  },[])
  return (
    <section className="work_container">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title" data-aos="fade-up">
              Let's have a look at some of our best work
            </div>
            {OurWorkJson &&
            OurWorkJson.length > 0 &&
            OurWorkJson.map((data, index) => (
              <Link to={`/work-details/${data.link_title}`} key={index}>
              <div className="work_box" data-aos="fade-right"
              data-aos-anchor-placement="top-bottom">
                <div className="work_img">
                  <img src={`${process.env.PUBLIC_URL}${data.image}`} alt="" />
                </div>
                <div className="work_content">
                  <div>
                    <div className="box_title">{data.title}</div>
                    <div className="box_content">{data.description}</div>
                  </div>
                  <div className="box_action">
                      See Details <img src={Go} alt="" />
                  </div>
                </div>
              </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
