import React from 'react'
import './Works.scss'
import Navigation from '../../Components/Navigation/Navigation'
import Heading from '../../Components/Heading/Heading'
import Footer from '../../Components/Footer/Footer'
import WorkBox from './Components/WorkBox'
import Contect from '../Home/Components/Contect'

export default function Works() {
  return (
    <>
    <Navigation offer={true}/>
    <Heading Title="Our Works" MiniTitle="" Links="Our Works"/>
    <WorkBox/>
    <Contect/>
    <Footer/>
    </>
  )
}
