import React from "react";
import Tick from "./../../../assets/images/tick.svg";

export default function Results({ id, selectedApp }) {
  return (
    <section className="results">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title" data-aos="fade-right">Results</div>
          </div>

          {selectedApp?.results?.result &&
            selectedApp.results.result.length > 0 &&
            selectedApp.results.result.map((c, i) => (
              <div className="col-md-12 col-lg-12 col-xl-6" key={i} data-aos="fade-left" data-aos-delay={i * 500}>
                <div className="result_wrapper">
                  <div className="icon">
                    <img src={Tick} alt="" />
                  </div>
                  <div className="text">{c}</div>
                </div>
              </div>
            ))}
          {/* <div className="col-md-12 col-lg-12 col-xl-6">
                    <div className="result_wrapper">
                        <div className="icon"><img src={Tick} alt="" /></div>
                        <div className="text">{selectedApp.results.result2}</div>
                    </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-6">
                    <div className="result_wrapper">
                        <div className="icon"><img src={Tick} alt="" /></div>
                        <div className="text">{selectedApp.results.result3}</div>
                    </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-6">
                    <div className="result_wrapper">
                        <div className="icon"><img src={Tick} alt="" /></div>
                        <div className="text">{selectedApp.results.result4}</div>
                    </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-6">
                    <div className="result_wrapper">
                        <div className="icon"><img src={Tick} alt="" /></div>
                        <div className="text">{selectedApp.results.result5}</div>
                    </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-6">
                    <div className="result_wrapper">
                        <div className="icon"><img src={Tick} alt="" /></div>
                        <div className="text">{selectedApp.results.result6}</div>
                    </div>
                </div> */}
        </div>
      </div>
    </section>
  );
}
