import React, {useState, useEffect} from "react";
import JSONFIle from "./../../../Data/Case_Studies.json";
import { Link } from "react-router-dom";
import AOS from "aos";
import 'aos/dist/aos.css';

export default function CaseCard({details}) {
  useEffect(()=>{
    AOS.init({
      duration: 3000,
    });
  },[])
  return (
    <>
    <div className="case_card"  data-aos="zoom-in">
    <Link to={`/case-studies/${details.route_title}`}>
      <div className={`case_img c_img${details.id}`}>
      <div className="title">{details.title}</div>
        <img src={`${process.env.PUBLIC_URL}${details.listImg}`} alt="" />
      </div>
      </Link>
      <div className="case_title">
        <div className="mini_title">{details.mini_title}</div>
        <div className="platform">
          {
            details.industries.industries_all.map((data) => (
              <span className="">{data}</span>
            )) 
          }
        </div>
      </div>
    </div>
    </>
  );
}
