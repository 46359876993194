import React,{useEffect} from 'react'
import CEO from './../../../assets/images/ceo.jpg'
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Content() {
    const options = {
        items: 1,
        nav: false,
        rewind: false,
        dots: true,
        loop: false,
        autoplay: true,
        margin:0,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:1
            }
        }
      };
      useEffect(() => {
        AOS.init({
          duration: 1500,
        }); 
      }, [])
    return (
        <section className='about_content'>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="title" data-aos="fade-up"><span>KoolMind Technolab</span> is the one-stop solution for all kinds of Mobile Apps, Web Apps & Software Development services.</div>
                        <div className="desc" data-aos="fade-up">We redefine the essence of client partnerships. In a landscape where organizations invest substantial time in quest of an ideal technology collaborator for business elevation, we stand out by offering a seamless fusion of expertise and responsibility. We are one of the top quality custom mobile app & web app development company. With an advanced level of experience in developing WEB & MOBILE APPS for more than 10 Years, we have provided excellent solutions to our clients and satisfied results have been given to our clients. We have delivered 80+ Mobile Apps & 25+ Softwares to our clients with 85% of repetition clients ratio.</div>
                        <div className="about_image">
                            {/* <OwlCarousel options={options} >
                                <img src={AboutImg} alt="" />
                                <img src={AboutImg} alt="" />
                                <img src={AboutImg} alt="" />
                                <img src={AboutImg} alt="" />
                            </OwlCarousel> */}
                        </div>
                        <div className="word_ceo" data-aos="zoom-out-right" data-aos-duration="3000">
                            <div className="thumbnail">
                                <img src={CEO} alt="" />
                                <div className="play"></div>
                            </div>
                            <div className="content">
                                {/* <div className="mini_title">TESTIMONIALS</div> */}
                                <div className="ceo_title">Words of our CEO</div>
                                <div className="ceo_desc">At KoolMind Technolab, we transcend the conventional realm of service providers by instilling a profound sense of responsibility at the core of our ethos. In the intricate landscape of technology partnerships, we recognize that clients often grapple with the challenge of finding a partner who not only delivers excellent results but also assumes complete RESPONSIBILITY for the journey. We believe that success is not just about providing a service; it's about taking on the MANTLE of our clients' ambitions and challenges. At KoolMind, we don't merely undertake mobile app development projects; we embrace them with a fervent commitment to taking complete RESPONSIBILITY. Your objectives become our mission, and every facet of your project is met with a personalized touch. By choosing KoolMind Technolab, you are not just engaging a software development firm; you are forging a partnership with a team that believes in the transformative power of assuming full RESPONSIBILITY for your success. It's not just a service; it's a PLEDGE, a PROMISE, and a PARTNERSHIP built on the cornerstone of unwavering responsibility.</div>
                                <div className="name">Neel Mevada</div>
                                <div className="position">Founder & CEO</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
