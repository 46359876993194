import React from 'react'
import './career.scss'
import Navigation from '../../Components/Navigation/Navigation';
import Heading from '../../Components/Heading/Heading';
import Description from './Components/Description';
import Footer from '../../Components/Footer/Footer';

export default function Career() {
  return (
    <>
    <Navigation/>
    <Heading Title="Career" MiniTitle="" Links="Career"/>
    <Description/>
    <Footer/>
    </>
  )
}
